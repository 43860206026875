import {SvgIcon, SvgIconProps} from "@mui/material";
import {FC} from "react";

export const AppIcon: FC<SvgIconProps> = (props) => (
    <SvgIcon
        {...props}
        version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="300.000000pt" height="300.000000pt" viewBox="0 0 300.000000 300.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
           fill="#000000" stroke="none">
            <path d="M1920 2804 c-41 -7 -97 -15 -123 -19 -27 -4 -46 -9 -43 -12 3 -3 72
5 155 17 170 26 208 21 246 -35 17 -25 26 -29 54 -27 19 2 44 1 57 -2 35 -9
82 -54 89 -86 6 -26 12 -30 53 -35 98 -14 143 -42 158 -99 3 -12 -13 -130 -35
-263 -41 -241 -46 -281 -35 -271 3 3 14 62 24 130 11 67 26 166 35 218 29 171
30 191 3 230 -18 26 -37 39 -78 52 -30 9 -67 17 -82 17 -21 1 -29 8 -37 34
-16 47 -64 79 -125 85 -38 3 -59 12 -81 32 -59 54 -91 59 -235 34z"/>
            <path d="M834 2781 c-17 -11 -37 -33 -45 -49 -12 -28 -17 -30 -81 -34 -76 -6
-110 -23 -139 -72 -15 -26 -28 -35 -59 -40 -51 -8 -107 -57 -115 -100 -6 -28
28 -232 40 -244 9 -10 5 26 -15 123 -11 53 -17 108 -14 121 9 36 69 83 115 90
28 5 43 13 49 28 20 53 82 86 163 86 49 0 53 3 68 33 9 20 32 40 59 55 28 13
37 22 25 22 -11 0 -34 -9 -51 -19z"/>
            <path d="M1020 2785 c47 -7 99 -13 115 -13 29 0 29 0 -5 8 -19 5 -71 11 -115
14 l-80 5 85 -14z"/>
            <path d="M1979 2786 c-2 -2 -58 -12 -124 -20 -66 -9 -142 -21 -170 -26 -27 -5
-113 -18 -190 -30 -210 -32 -320 -49 -420 -65 -49 -8 -153 -24 -230 -35 -350
-52 -378 -60 -403 -115 -18 -38 -16 -55 28 -282 23 -115 72 -370 111 -568 38
-198 76 -391 84 -430 8 -38 39 -200 70 -360 103 -529 113 -576 132 -597 46
-51 98 -58 254 -33 184 30 276 44 414 65 144 22 340 52 455 71 36 5 153 23
260 40 107 16 211 34 232 39 37 10 81 53 93 89 3 11 -14 122 -39 248 -43 219
-103 528 -256 1313 -135 693 -121 643 -183 680 -28 17 -106 28 -118 16z m118
-32 c13 -8 29 -24 37 -37 8 -12 38 -141 66 -287 28 -146 73 -377 100 -515 45
-228 161 -826 211 -1085 11 -58 27 -135 35 -172 22 -106 19 -135 -19 -173 -20
-20 -48 -36 -68 -39 -19 -3 -140 -22 -269 -41 -129 -20 -275 -42 -325 -50 -49
-8 -254 -40 -455 -70 -201 -30 -382 -58 -402 -61 -46 -7 -98 9 -126 39 -23 25
-20 7 -202 947 -39 201 -93 478 -120 615 -132 674 -124 625 -104 663 23 43 53
58 144 71 71 10 194 30 425 66 55 8 159 24 230 35 72 11 173 26 225 34 52 8
158 25 235 37 77 11 176 27 220 34 86 14 127 11 162 -11z"/>
            <path d="M560 2475 c-10 -12 -10 -15 0 -15 7 0 10 -7 6 -15 -3 -8 -1 -15 4
-15 12 0 13 -37 2 -44 -11 -6 -28 5 -34 24 -5 13 -7 12 -13 -2 -10 -25 1 -48
23 -48 24 0 64 42 58 61 -2 8 -7 26 -10 42 -7 30 -18 34 -36 12z"/>
            <path d="M1379 2473 c-2 -15 2 -68 7 -105 5 -34 9 -38 35 -38 28 0 29 1 29 53
0 28 3 62 6 75 6 20 3 22 -35 22 -22 0 -41 -3 -42 -7z"/>
            <path d="M1538 2393 c-2 -38 -3 -69 -3 -70 0 -1 12 -4 26 -8 24 -6 26 -3 37
47 7 29 15 63 18 76 6 20 3 22 -34 22 l-40 0 -4 -67z"/>
            <path d="M1198 2433 c-14 -4 -13 -12 11 -71 25 -59 31 -67 52 -64 13 2 25 5
27 7 2 1 -5 32 -14 69 -17 69 -23 73 -76 59z"/>
            <path d="M1706 2389 c-38 -121 -39 -121 -25 -130 8 -5 18 -9 22 -9 8 0 70 130
65 136 -2 1 -15 5 -30 9 -19 5 -29 3 -32 -6z"/>
            <path d="M1063 2353 c-20 -7 -16 -19 23 -78 38 -56 42 -59 68 -49 13 5 10 16
-17 70 -30 61 -42 70 -74 57z"/>
            <path d="M559 2329 c-11 -11 -18 -26 -16 -32 6 -20 42 -19 61 2 15 17 16 23 6
36 -17 20 -26 19 -51 -6z"/>
            <path d="M1806 2258 c-27 -46 -33 -64 -24 -70 21 -13 23 -11 68 53 34 49 40
63 29 70 -26 16 -36 9 -73 -53z"/>
            <path d="M950 2255 c-11 -14 -6 -23 35 -65 49 -49 68 -59 80 -40 7 11 -75 120
-91 120 -6 0 -17 -7 -24 -15z"/>
            <path d="M550 2241 c0 -6 5 -13 10 -16 6 -3 10 -17 10 -31 0 -15 6 -24 15 -24
15 0 15 11 0 58 -7 21 -35 32 -35 13z"/>
            <path d="M600 2231 c0 -5 7 -21 15 -37 12 -25 15 -26 21 -9 5 11 1 22 -9 30
-9 7 -14 15 -10 18 3 4 1 7 -5 7 -7 0 -12 -4 -12 -9z"/>
            <path d="M1896 2175 c-39 -45 -44 -56 -31 -61 11 -5 33 9 68 42 28 27 54 50
56 52 7 4 -21 22 -35 22 -5 0 -32 -25 -58 -55z"/>
            <path d="M873 2163 c-14 -5 -18 -33 -5 -33 4 0 31 -18 60 -41 42 -31 57 -38
67 -29 11 9 3 21 -45 61 -33 27 -60 49 -62 48 -2 0 -9 -3 -15 -6z"/>
            <path d="M1968 2097 c-53 -39 -73 -67 -48 -67 6 0 40 19 75 43 49 33 62 46 53
55 -16 16 -15 16 -80 -31z"/>
            <path d="M600 2115 c-16 -19 -6 -45 18 -45 30 0 34 13 10 28 -13 7 -16 11 -7
9 9 -3 16 1 16 9 0 18 -21 18 -37 -1z"/>
            <path d="M810 2055 c-10 -12 1 -21 61 -50 48 -23 76 -32 83 -25 12 12 16 9
-66 53 -56 30 -68 34 -78 22z"/>
            <path d="M1250 2017 c-20 -19 -34 -40 -32 -50 2 -10 -2 -25 -9 -33 -9 -11 -9
-18 -1 -26 16 -16 34 1 28 27 -7 27 26 57 52 48 11 -5 13 -3 7 8 -6 10 -5 11
3 4 7 -6 15 -32 18 -57 6 -43 5 -47 -17 -50 -16 -2 -25 -11 -27 -25 -4 -25 7
-30 26 -11 9 9 12 6 12 -15 0 -15 -4 -27 -8 -27 -11 0 -32 -45 -32 -67 0 -12
3 -13 14 -4 7 6 21 8 30 5 15 -6 15 -9 1 -30 -21 -33 -20 -47 4 -34 28 15 78
12 84 -4 7 -17 -13 -26 -58 -26 -22 0 -41 -8 -54 -22 -52 -55 -116 -74 -148
-43 -12 12 -18 13 -29 4 -18 -15 -8 -29 21 -29 34 0 40 -18 13 -40 -22 -18
-24 -18 -43 0 -11 10 -38 27 -60 39 -77 41 -100 58 -101 72 -3 55 -34 95 -34
45 0 -31 -38 -46 -55 -22 -17 22 -28 20 -20 -4 7 -21 -1 -25 -23 -11 -9 5 -11
17 -6 36 5 20 4 26 -5 21 -6 -4 -11 -14 -11 -23 0 -13 -3 -13 -15 -3 -12 10
-15 10 -15 -3 0 -8 15 -24 33 -36 l32 -21 -30 0 c-36 -1 -18 -14 33 -25 24 -5
38 -3 51 9 15 14 19 12 44 -20 38 -47 54 -93 36 -104 -13 -9 -10 -11 71 -40
63 -22 86 -22 128 -1 38 19 42 20 50 4 9 -15 16 -12 62 21 57 41 55 43 35 -34
-5 -21 -5 -22 5 -7 14 22 30 22 30 -1 0 -10 11 -25 25 -32 14 -7 25 -22 25
-32 0 -10 6 -21 14 -24 12 -5 13 -11 4 -30 -6 -13 -15 -24 -19 -24 -5 0 -31
-20 -59 -45 -56 -50 -67 -53 -86 -27 -12 16 -14 16 -30 0 -17 -16 -17 -18 2
-24 10 -3 29 -11 41 -17 21 -10 53 -4 112 20 11 4 12 -2 6 -33 -10 -58 -23
-78 -40 -64 -16 14 -35 5 -35 -16 0 -15 35 -20 44 -5 3 5 20 11 37 13 33 5 33
3 14 -58 -6 -20 24 -61 121 -161 l31 -32 -26 -10 c-25 -9 -25 -9 -3 -10 40 -2
59 -18 96 -86 56 -101 47 -170 -20 -163 -21 2 -29 -2 -32 -15 -3 -15 2 -17 40
-12 75 11 87 15 108 37 22 24 26 62 10 103 -7 19 -6 33 5 54 13 27 13 29 -11
40 -14 6 -32 11 -41 11 -9 0 -14 3 -10 6 3 3 -3 16 -14 28 -15 15 -19 31 -17
58 3 34 2 36 -13 22 -16 -14 -18 -11 -34 35 -9 29 -29 61 -46 75 l-29 25 48
75 c28 45 54 76 64 76 23 0 71 -22 84 -39 13 -17 48 -7 42 12 -3 6 -13 14 -24
16 -18 3 -34 18 -34 32 0 4 13 9 29 11 27 4 29 1 35 -33 3 -20 8 -44 12 -52 3
-9 -3 -17 -18 -21 l-23 -7 23 -11 c12 -6 22 -18 23 -27 1 -9 2 -31 4 -50 2
-23 -4 -44 -17 -62 -11 -14 -18 -35 -16 -45 3 -16 13 -19 63 -19 37 0 72 -7
92 -18 49 -26 80 -22 120 18 19 19 41 35 49 35 19 0 18 26 -2 34 -10 4 -18 0
-21 -13 -5 -22 -50 -43 -88 -43 -14 0 -43 11 -65 26 -39 26 -40 28 -40 85 0
51 3 61 25 75 13 9 31 16 40 16 8 0 15 5 15 10 0 14 -53 12 -69 -2 -11 -11
-13 -9 -8 11 3 14 14 71 23 127 16 96 16 105 0 129 -19 29 -64 34 -110 10 -34
-18 -44 -19 -28 -3 7 7 12 18 12 26 0 18 -35 11 -35 -8 -1 -27 -16 -40 -45
-40 -18 0 -34 -8 -44 -22 -15 -21 -16 -21 -16 -3 1 11 7 31 15 45 8 14 14 40
15 57 0 29 2 32 23 24 12 -5 66 -12 120 -16 84 -6 96 -5 91 8 -3 9 4 21 18 31
12 9 17 16 10 16 -9 0 -10 5 -2 19 5 11 10 28 10 38 0 10 21 44 47 76 40 49
51 57 80 57 40 0 80 19 108 50 l20 23 -22 -7 c-16 -5 -22 -2 -26 11 -3 15 -6
13 -15 -9 -10 -23 -18 -28 -47 -28 -34 0 -46 12 -25 25 13 8 13 25 1 25 -5 0
-12 -8 -16 -18 -3 -11 -17 -24 -30 -30 -14 -6 -25 -18 -25 -25 0 -15 -71 -87
-87 -87 -5 0 -29 -15 -52 -33 -42 -33 -43 -34 -77 -18 -35 16 -29 31 13 31 27
0 41 19 22 31 -8 5 -20 3 -29 -5 -17 -14 -100 -10 -100 5 0 5 4 9 10 9 5 0 25
16 45 35 40 40 44 55 15 55 -13 0 -20 -7 -20 -20 0 -22 -12 -26 -112 -35 -63
-5 -67 -4 -87 21 -23 29 -30 57 -9 36 7 -7 22 -12 35 -12 14 0 23 -6 23 -15 0
-8 -5 -15 -12 -15 -6 0 -9 -2 -6 -5 3 -3 13 -3 22 1 21 8 21 43 -1 51 -13 5
-12 8 8 16 l24 10 -25 6 c-25 7 -42 41 -20 41 7 0 10 7 6 15 -3 9 0 15 8 15 8
0 23 3 33 7 16 6 16 5 4 -10 -8 -10 -9 -17 -3 -17 14 0 62 -47 62 -60 0 -6 9
-10 20 -10 26 0 26 26 0 33 -11 3 -22 17 -25 33 -12 59 -28 79 -70 84 -43 5
-57 28 -35 55 15 18 30 19 30 2 0 -14 78 -40 106 -36 11 2 25 0 32 -4 15 -10
32 0 32 19 0 17 -23 18 -51 2 -22 -14 -36 -2 -71 61 -19 35 -49 40 -137 19
-47 -11 -54 -10 -100 15 -63 35 -88 34 -131 -6z m142 -43 c22 -11 43 -14 74
-9 l43 6 -6 -36 c-4 -26 -1 -41 13 -56 17 -19 17 -21 1 -48 -9 -16 -17 -38
-17 -48 0 -11 -9 -28 -20 -38 -27 -24 -25 -56 5 -89 19 -20 33 -26 68 -26 38
0 53 -7 112 -55 83 -66 116 -76 157 -45 39 28 50 22 32 -18 l-14 -34 -100 4
c-117 3 -122 0 -147 -85 -15 -54 -15 -58 3 -78 20 -22 48 -21 129 6 123 40
132 31 109 -100 -8 -49 -14 -102 -12 -118 2 -17 -2 -32 -8 -34 -7 -2 -14 19
-18 56 -11 94 -58 135 -110 96 -11 -8 -32 -15 -47 -15 -38 0 -83 -41 -124
-114 -41 -73 -42 -82 -15 -111 20 -21 82 -136 77 -141 -2 -2 -33 31 -70 72
-57 64 -67 80 -67 113 0 20 -7 46 -15 57 -8 10 -15 35 -15 54 0 19 -7 44 -15
54 -14 18 -13 23 14 50 52 56 51 61 -42 200 -45 67 -60 75 -99 53 -32 -18 -98
-23 -98 -8 0 12 49 51 64 51 6 0 31 16 55 35 27 22 55 35 73 35 27 0 78 38 78
57 -1 16 -25 40 -56 56 -51 26 -55 36 -43 113 6 38 10 88 10 112 -1 23 1 42 4
42 3 0 20 -7 37 -16z m-325 -475 c64 -37 59 -57 -7 -34 -44 16 -60 29 -60 53
0 17 6 16 67 -19z"/>
            <path d="M2015 2008 c-84 -39 -78 -35 -71 -46 8 -13 156 49 156 65 0 18 -6 17
-85 -19z"/>
            <path d="M622 2020 c-20 -9 -21 -12 -10 -32 7 -13 14 -25 15 -27 1 -1 6 0 12
4 8 4 8 12 1 25 -8 15 -7 18 3 14 8 -3 20 2 27 11 10 13 9 15 -6 14 -11 0 -29
-4 -42 -9z"/>
            <path d="M775 1950 c-9 -14 -13 -13 70 -34 91 -23 87 -23 91 -11 3 8 -37 24
-137 52 -9 3 -20 0 -24 -7z"/>
            <path d="M2031 1920 c-36 -9 -67 -23 -69 -30 -3 -7 0 -10 6 -6 6 3 37 11 69
17 80 14 94 19 88 30 -8 11 -14 10 -94 -11z"/>
            <path d="M760 1852 c0 -6 19 -13 43 -16 102 -12 127 -14 132 -6 3 4 -21 11
-53 14 -31 4 -72 10 -89 13 -20 3 -33 1 -33 -5z"/>
            <path d="M2030 1840 c-47 -3 -80 -8 -74 -12 6 -4 48 -4 95 0 108 9 91 19 -21
12z"/>
            <path d="M850 1773 c-36 -2 -69 -9 -75 -15 -13 -13 155 -1 170 13 5 5 2 8 -10
7 -11 -1 -49 -3 -85 -5z"/>
            <path d="M1265 1380 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"/>
            <path d="M2309 1083 c-1 -5 -1 -15 0 -24 1 -9 -8 -19 -21 -22 -35 -10 -14 -19
26 -12 32 6 52 25 26 25 -5 0 -10 9 -10 20 0 18 -19 29 -21 13z"/>
            <path d="M2305 970 c-3 -5 -2 -10 4 -10 5 0 8 -9 6 -20 -2 -12 2 -20 9 -20 20
0 39 27 32 45 -6 17 -41 20 -51 5z"/>
            <path d="M2354 863 c1 -10 8 -31 15 -47 9 -22 15 -26 23 -18 9 9 9 15 0 24 -7
7 -12 22 -12 35 0 14 -6 23 -15 23 -9 0 -13 -7 -11 -17z"/>
            <path d="M2307 862 c-7 -10 32 -57 40 -48 7 7 -17 56 -28 56 -4 0 -10 -3 -12
-8z"/>
            <path d="M2345 750 c-15 -16 -15 -22 -5 -35 7 -8 16 -15 21 -15 18 0 48 40 43
55 -8 21 -37 19 -59 -5z"/>
            <path d="M2362 667 c-18 -19 -20 -28 -12 -60 11 -49 17 -55 37 -34 13 12 14
17 4 17 -8 0 -11 6 -7 15 3 8 1 15 -4 15 -6 0 -10 9 -10 20 0 13 7 20 20 20
12 0 20 -7 20 -16 0 -13 3 -14 15 -4 11 9 12 17 4 31 -13 25 -41 24 -67 -4z"/>
            <path d="M900 2770 c-19 -5 -47 -23 -63 -40 -40 -44 -27 -48 16 -5 44 44 61
44 322 10 197 -26 221 -28 214 -21 -5 6 -167 29 -309 46 -52 6 -106 13 -120
15 -14 2 -41 -1 -60 -5z"/>
            <path d="M1177 2768 c-7 -4 3 -8 24 -8 20 0 58 -4 85 -9 27 -5 83 -11 124 -14
66 -5 69 -5 25 4 -109 20 -246 35 -258 27z"/>
            <path d="M1593 2755 c-45 -7 -88 -16 -95 -19 -18 -8 93 4 177 20 92 16 26 16
-82 -1z"/>
            <path d="M1127 2703 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
            <path d="M2220 2700 c31 -7 55 -20 78 -45 28 -31 32 -41 32 -91 0 -53 13 -381
26 -682 3 -73 8 -131 11 -128 5 5 -13 625 -23 785 -6 87 -8 96 -38 128 -26 29
-40 36 -81 39 l-50 3 45 -9z"/>
            <path d="M747 2671 c-84 -6 -89 -8 -124 -44 -21 -20 -32 -37 -26 -37 7 0 17 8
22 19 21 38 72 51 203 52 67 1 170 5 228 9 102 6 100 7 -55 7 -88 0 -199 -3
-248 -6z"/>
            <path d="M2417 2574 c75 -18 113 -56 113 -110 0 -33 -123 -795 -136 -841 -4
-13 -2 -23 3 -23 10 0 22 68 104 568 50 309 50 313 32 344 -28 48 -65 70 -125
74 l-53 4 62 -16z"/>
            <path d="M2374 2290 c0 -58 1 -81 3 -52 2 28 2 76 0 105 -2 28 -3 5 -3 -53z"/>
            <path d="M441 2217 c-1 -16 22 -140 55 -302 18 -90 62 -315 69 -357 6 -35 15
-51 15 -27 0 16 -128 679 -134 694 -3 6 -5 2 -5 -8z"/>
            <path d="M2384 2020 c0 -63 1 -89 3 -57 2 31 2 83 0 115 -2 31 -3 5 -3 -58z"/>
            <path d="M2471 1885 c-13 -87 -13 -123 -1 -82 11 35 24 157 17 157 -2 0 -10
-34 -16 -75z"/>
            <path d="M2393 1770 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z"/>
            <path d="M2432 1644 l-21 -129 54 -280 c30 -154 55 -286 55 -294 0 -8 3 -12 6
-8 6 6 -11 125 -27 187 -21 83 -78 400 -74 412 2 7 6 29 9 48 3 19 11 65 17
103 6 37 9 73 7 79 -2 6 -14 -46 -26 -118z"/>
            <path d="M580 1501 c0 -14 -66 -413 -79 -479 -4 -23 -5 -44 -2 -47 9 -5 95
524 87 532 -3 4 -6 1 -6 -6z"/>
            <path d="M595 1378 c-4 -18 -38 -217 -75 -443 -74 -447 -74 -441 -18 -493 22
-20 125 -47 137 -36 5 5 -15 11 -63 19 -26 4 -51 18 -74 41 -42 42 -45 3 44
543 36 216 63 395 61 398 -3 2 -8 -11 -12 -29z"/>
            <path d="M634 1170 c3 -52 11 -250 17 -439 6 -189 14 -354 19 -365 16 -43 82
-86 131 -86 10 0 19 5 19 10 0 6 -16 10 -35 10 -26 0 -45 9 -71 33 l-36 33
-18 442 c-9 243 -20 445 -24 449 -4 5 -5 -35 -2 -87z"/>
            <path d="M487 935 c-3 -16 -8 -57 -11 -90 l-5 -60 15 70 c8 39 13 79 11 90 -2
15 -5 13 -10 -10z"/>
            <path d="M2530 908 c0 -8 16 -93 35 -189 37 -180 37 -208 5 -247 -20 -24 -78
-52 -108 -52 -11 0 -25 -14 -35 -36 -22 -46 -82 -73 -159 -74 -43 0 -58 -4
-58 -14 0 -24 -50 -75 -83 -85 -31 -9 -31 -9 -5 -10 29 -1 98 56 98 81 0 12
17 16 69 20 79 6 120 27 145 73 14 26 26 34 57 39 50 8 105 56 113 99 5 30 -5
93 -49 317 -16 82 -25 110 -25 78z"/>
            <path d="M461 775 c-1 -5 -8 -53 -17 -105 -8 -52 -18 -112 -21 -134 -8 -61 28
-116 91 -135 60 -18 86 -21 86 -11 0 5 -14 10 -31 10 -50 0 -98 29 -120 72
l-20 39 21 124 c12 69 19 130 17 137 -3 7 -6 9 -6 3z"/>
            <path d="M2368 377 c-26 -31 -29 -32 -130 -38 -56 -4 -166 -7 -243 -8 -77 -1
-147 -6 -155 -11 -20 -13 434 -1 480 12 34 10 90 54 90 70 0 15 -15 6 -42 -25z"/>
            <path d="M623 379 c9 -5 17 -18 17 -28 0 -9 15 -32 34 -50 28 -28 45 -35 88
-39 40 -3 60 -11 83 -32 36 -33 75 -55 75 -41 0 5 -8 12 -19 16 -10 3 -31 19
-47 35 -23 23 -38 30 -70 30 -51 0 -102 33 -129 83 -11 20 -27 37 -35 37 -12
-1 -11 -3 3 -11z"/>
            <path d="M2162 281 c-38 -52 -67 -55 -288 -26 -110 14 -214 28 -231 31 -18 2
-33 1 -33 -5 0 -5 21 -11 48 -13 26 -3 133 -15 237 -28 175 -21 193 -22 227
-7 20 8 44 27 54 41 23 37 12 42 -14 7z"/>
            <path d="M1355 250 c-29 -7 -28 -8 10 -8 22 0 51 4 65 8 23 7 22 8 -10 8 -19
0 -48 -4 -65 -8z"/>
            <path d="M1632 251 c15 -5 67 -12 115 -14 60 -4 72 -3 38 2 -96 15 -181 21
-153 12z"/>
            <path d="M1098 212 c-54 -8 -98 -19 -98 -23 0 -5 6 -6 13 -4 6 3 61 12 121 20
60 8 112 17 114 20 8 8 -46 3 -150 -13z"/>
            <path d="M1870 220 c19 -5 71 -11 115 -14 l80 -5 -85 14 c-47 7 -98 13 -115
13 -29 0 -29 0 5 -8z"/>
        </g>
    </SvgIcon>
)